<template>
  <div class="top_container">
    <img class="top_img" v-if="topadv.image && topadv.image.url" :src="topadv.image && topadv.image.url" @click="click(topadv)" />
    <div class="content">
      <div class="left">
        <div class="app">
          <span>欢迎使用！</span>
        </div>
        <div class="flex_row" v-if="!isLogin">
          <div class="login_box" @click="$router.push('/login')">请登录</div>
          <div class="login_box zhuce" @click="$router.push('/register')">免费注册</div>
        </div>
        <div v-else class="ml_20 flex_c_c">
          {{ name }}：您好！
          <div class="login_box" style="margin-left: 8px" @click="handleLogOut">安全退出</div>
        </div>
      </div>

      <div class="right">
        <div class="item green"><a class="item" href="http://api.mingyuanyyw.com/down/web/url" target="_blank">添加网址到桌面</a></div>
        <!-- <div class="item green" @click="downwn" >添加网址到桌面</div> -->
        <div class="item" @click="hanldeGo('/index/subRouter/order')">我的订单</div>
        <div class="item" @click="hanldeGo('/index/subRouter/homepage')">会员中心</div>
        <!-- <div class="item" @click="hanldeGo('/index/subRouter/einvoice')">电子发票</div> -->
        <div class="item"><a class="item" href="https://invoice.youepiao.com/select/#/passport/login?agencyId=6790883838869508096&stamp=1731983331596" target="_blank">电子发票</a></div>
        <div class="item"><a class="item" href="https://www.51yywd.com/login?redirect=%2Fhome%2FUser%2Fhdapi.html" target="_blank">质检报告查询</a></div>

        <el-popover placement="bottom" width="130" trigger="hover">
          <slot>
            <img style="width: 130px; height: 130px; justify-content: center" :src="settingImg.weichat_img" alt="" />
          </slot>
          <div slot="reference" class="item wx_item">下载APP</div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump, backToLogin } from "@/utils";
import { LgetItem, LsetItem } from "@/utils/storage";

export default {
  data() {
    return {
      settingImg: {
        weichat_img: "",
      },
      topadv: {
        image: {
          url: "",
        },
      },
      isLogin: false,
    };
  },
  created() {
    this.handleisLogin();
    this.$api("home.getNavInfo").then((res) => {
      this.settingImg = res.data.settingImg;
      this.weichat_img = this.settingImg.weichat_img;
      this.topadv = res.data.topadv[0];
    });
  },
  mounted() {
    this.$bus.$on("logout", this.handleisLogin);
  },
  computed: {
    name() {
      if (LgetItem("info")) {
        return LgetItem("info").organization_name;
      } else {
        return "游客";
      }
    },
  },
  methods: {
    handleisLogin() {
      if (LgetItem("token")) this.isLogin = true;
      else this.isLogin = false;
    },
    async hanldeGo(path) {
      this.$store.commit("CHANGE_TAB", -1);
      await backToLogin();
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    click(item) {
      handleJump(item.jump);
      this.$api("home.getClick", { adv_id: item.id });
    },
    add() {
      this.$api("home.toDesk").then((res) => {
        console.log(res);
      });
    },
    urlDownload(content, fileName) {
      const eleLink = document.createElement("a");
      eleLink.download = fileName;
      eleLink.style.display = "none";
      const blob = new Blob([content]);
      eleLink.href = URL.createObjectURL(blob);
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    downwn() {
      // const { protocol, href, hostname } = location;
      // const content = `URL=https://pc.web.wiscode.cn
      // IconFile=http://pc.web.wiscode.cn/favicon.ico
      // IconIndex=0
      // HotKey=1613
      // IDList=
      // [{000214A0-0000-0000-C000-000000000046}]
      // Prop3=19,2`;
      // this.urlDownload(content, "智联医药.url");
    },
    handleLogOut() {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          LsetItem("token", "");
          LsetItem("info", "");
          LsetItem("canchange", false);

          this.$store.commit("CHANGE_TAB", 0);
          // this.$store.commit("CHANGE_MENUID", item.id);
          // this.activeTab = ind;
          // localStorage.setItem("mid", item.id);

          localStorage.setItem("closePop", false);
          this.$router.replace("/login");
        })
        .catch(() => {});
    },
  },
  destroyed() {
    this.$bus.$off("logout", this.handleisLogin);
  },
};
</script>

<style lang="less" scoped>
.top_container {
  font-size: 12px;
  font-family: Microsoft YaHei;
  background-color: #f8f8f8;
  .top_img {
    width: 100%;
    height: 80px !important;
  }
  .content {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 100%;
    .left {
      display: flex;
      .app {
        // margin-top: 2px;
        border-right: 1px solid #cccccc;
        padding-right: 15px;
        display: flex;
        span {
          line-height: 1.5;
        }
      }
      .login_box {
        cursor: pointer;
        width: 65px;
        // line-height: 100%;
        height: 20px;
        background: @themeColor;
        border-radius: 2px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
      }
      .zhuce {
        background: #f1622f;
      }
    }
    .right {
      display: flex;
      .item {
        text-decoration: none;
        color: #515a6e;
        cursor: pointer;
        padding: 0 10px;
        border-right: 1px solid #cccccc;
        &:last-child {
          border: none;
          padding-right: 0;
        }
        &:hover {
          color: @themeColor;
        }
      }
      .wx_item {
        margin-left: 10px;
      }
      .green {
        color: @themeColor;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.code_img {
  width: 130px;
  height: 130px;
  background-color: #ccc;
  margin-right: 15px;
  img {
    width: 130px;
    height: 130px;
  }
}
.str {
  font-size: 12px;
  line-height: 2.4;
}
.apple {
  width: 132px;
  height: 30px;
  background: #ff6766;
  box-shadow: 0px 0px 4px 0px rgba(255, 103, 102, 0.3);
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  padding-left: 22px;
  img {
    margin-right: 10px;
  }
}
.android {
  width: 132px;
  height: 30px;
  background: #26d871;
  box-shadow: 0px 0px 4px 0px rgba(38, 216, 113, 0.3);
  border-radius: 4px;
  color: #fff;
  margin-top: 7px;
  padding-left: 22px;

  img {
    margin-right: 10px;
  }
}
</style>
